var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.windowClass},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('chart-collection-bar',{attrs:{"rawdata":_vm.COLLECTIONS}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"mr-8"},[_vm._v("Date Covered: "+_vm._s(this.from_date)+" to "+_vm._s(this.to_date)+" ")]),_c('span',{staticClass:"text-warning mr-8"},[_vm._v("No. of Trans: "+_vm._s(_vm.TRANSCOUNT)+" ")]),_c('span',{staticClass:"text-info  ml-5 mr-8"},[_vm._v("Requested: "+_vm._s(_vm.$money(_vm.TOTALREQ))+" ")]),_c('span',{staticClass:"text-success  ml-5"},[_vm._v("Recieved: "+_vm._s(_vm.$money(_vm.RECIEVABLE))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',[_c('v-icon',{attrs:{"left":"","color":"success"}},[_vm._v("mdi-credit-card")]),_vm._v(" Buy Credits Summaries "),_c('v-spacer'),_c('span',{staticStyle:{"width":"200px","margin-top":"24px"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"From Date","value":_vm.from_date,"filled":"","readonly":"","dense":"","outlined":"","append-icon":"mdi-calendar"}},on))]}}]),model:{value:(_vm.from_menu),callback:function ($$v) {_vm.from_menu=$$v},expression:"from_menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.from_menu=false}},model:{value:(_vm.from_date),callback:function ($$v) {_vm.from_date=$$v},expression:"from_date"}})],1)],1),_c('span',{staticClass:"ml-2",staticStyle:{"width":"200px","margin-top":"24px"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"To Date","value":_vm.to_date,"readonly":"","filled":"","dense":"","outlined":"","append-icon":"mdi-calendar"}},on))]}}]),model:{value:(_vm.to_menu),callback:function ($$v) {_vm.to_menu=$$v},expression:"to_menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.to_menu=false, _vm.getBuys()}},model:{value:(_vm.to_date),callback:function ($$v) {_vm.to_date=$$v},expression:"to_date"}})],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_vm._v("Per Account Transaction")]),_c('v-data-table',{staticClass:"va-table",attrs:{"dense":"","headers":_vm.headers,"items":_vm.buycredits},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(item.user_id))]),_c('td',[_vm._v(_vm._s(_vm.$money(item.req_amount))+" ")]),_c('td',[_vm._v(_vm._s(_vm.$money(_vm.valueAmount(item.amount))))]),_c('td',[_vm._v(_vm._s(_vm.$moment(item.req_dt).format("YYYY-MM-DD HH:mm a")))]),_c('td',{staticClass:"text-right text-success"},[_vm._v(" "+_vm._s(item.status)+" ")])])}),0)]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_vm._v(" Daily Summary")]),_c('v-data-table',{staticClass:"va-table",attrs:{"dense":"","headers":_vm.sumheaders,"items":_vm.summaries},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.req_date))]),_c('td',[_vm._v(_vm._s(item.trans_count))]),_c('td',[_vm._v(_vm._s(_vm.$money(item.req_amount)))]),_c('td',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.$money(item.amount)))])])}),0)]}}])})],1)],1)],1)],1)],1)],1)],1),_c('va-cashout-confirm',{attrs:{"show":_vm.form,"cashout":_vm.select_item},on:{"DialogEvent":_vm.afEvent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }